<template>
    <div>
        <v-row>
            <v-col cols="12">
                <Editor :url="url" ref="editor" :fields="fields"
                        icon="mdi-handshake-outline"
                        testo-list="Cruscotto"
                        :args="args"
                        :filters="filters"
                        :no-insert="!$auth('staff')"
                        :no-update="!$auth('staff')"
                        :no-delete="!$auth('staff')"
                >
                    <template #extra>
                        <v-container>
                            <v-row>
                                <v-col cols="4">
                                    <div>
                                        <p>
                                            <v-chip small class="mr-2">{{ convenzione.codice }}</v-chip>
                                            {{ convenzione.nome }}<br>
                                            {{ convenzione.tipo }}<br>
                                            {{ convenzione.tipo_attivita }}
                                        </p>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </template>
                </Editor>

            </v-col>
        </v-row>
    </div>
</template>

<script>
import Editor from "@/components/Editor";
import {mapState} from "vuex";

export default {
    data: () => ({
        url: '/audit/cruscotto/',
        fields: [
            {text: 'Lotto', value: 'codice_lotto'},
            {text: 'Fornitore', value: 's_fornitore.last_name'},
            {text: 'Campione', value: 'campione'},
            {text: 'Validate da SGS', value: 'validate'},
            {text: 'Approvate Da Consip', value: 'approvato_consip'},
            {text: 'Ispezioni non conformi rilevate da Consip', value: 'non_approvato_consip'},
            {text: 'Punteggio 5 Conforme', value: 'ncc'},
            {text: 'Punteggio 4 Conforme con osservazioni', value: 'nco'},
            {text: 'Punteggio 3 Non conforme lieve', value: 'ncl'},
            {text: 'Punteggio 2 Non conforme Importante', value: 'nci'},
            {text: 'Punteggio 1 Non conforme grave', value: 'ncg'},
            {text: 'Punteggio 0 Non Valutabile', value: 'ncnossrvabili'},
            {text: 'Ispezioni interrotte', value: 'ispezioni_interrotte'},
            {text: 'Ispezioni con almeno una Non Conformità Grave - Punteggio 1', value: 'ispezioni_ncg'},
            {text: '% Ispezioni con Non Conformità Gravi', value: 'perc_ispezioni_ncg'},
        ],
        convenzione: {},
    }),
    async mounted() {
        await this.loadConvenzione();
    },
    computed: {
        ...mapState(['dataset']),
        args() {
            return {convenzione: this.$route.params.id};
        },
        filters() {
            return [
                {field: 'codice_lotto', label: 'Lotto'},
                {
                    field: 'fornitore',
                    label: 'Fornitore',
                    type: 'ajax',
                    url: '/backend/autocomplete/userprofile',
                    filter: {ruoli: 'fornitore', convenzione_id: this.$route.params.id}
                },
            ]
        },
    },
    methods: {
        async loadConvenzione() {
            const id = this.$route.params.id;
            const res = await this.$http.get(`/audit/convenzione/${id}/`);
            if (res.ok) {
                this.convenzione = res.result.data;
            }
        },
    },
    components: {
        Editor,
    },
}
</script>